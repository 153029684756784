<template>
  <div
    :class="{
      'fixed--pinned': scrolled,
      'fixed--unpinned': !scrolled,
    }"
    class="z-30 top-0 transition-colors duration-200"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'AppFixedHeader',
  data () {
    return {
      limitPosition: 600,
      scrolled: false,
      bg: false,
      lastPosition: 0
    }
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
        if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
          this.scrolled = true
          // move up!
        }
        if (this.lastPosition > window.scrollY) {
          this.scrolled = false
          // move down
        }

        if (window.scrollY > 10) {
          this.bg = true
        } else {
          this.bg = false
        }
        // this.$menu.show = false
        this.lastPosition = window.scrollY
        // this.scrolled = window.scrollY > 250
    }
  }
}
</script>
<style lang="scss">
.fixed-menu{
  margin-bottom: 20px;
}
body.post-type-archive-product,
body.home{
  .fixed-menu {
    margin-bottom: -107px;
  }
}
.fixed-header{
  // will-change: transform;
}
.fixed--pinned {
  // transform: translateY(0%);
//  background: #fff;
//  color: #333;
}
.fixed--unpinned {
  // transform: translateY(-100%);
  //  background: transparent;
}
</style>
