<template>
<div>
  <div v-bind:class="{readmore: readMore}" class="max-h-32 relative overflow-hidden prose text-primary mb-4">
    <div id="info-box" ref="infoBox">
      <slot />
    </div>
  </div>
  <button v-if="height > 128" type="button" v-bind:class="{readmore: readMore}" name="button" class="relative mb-8" v-on:click="readMore = !readMore">
    <span v-if="readMore==false"  class="text-primary font-bold tracking-wider uppercase flex gap-x-2">
      Read More
      <icon-down></icon-down>
    </span>
    <span v-if="readMore==true" @click="goto('infoBox')" class="text-primary font-bold tracking-wider uppercase flex gap-x-2">
      Show Less
      <icon-down class="rotate-180 transform"></icon-down>
    </span>
  </button>
</div>
</template>
<script>
export default {
  data () {
    return {
      readMore: false,
      height:0,
    }
  },
  methods: {
    myFilter: function() {
        this.isActive = !this.isActive;
        // some code to filter users
      },
      goto(refName) {
     var element = this.$refs[refName];
     var top = element.offsetTop;

     window.scrollTo(0, 500);
   }
  },

  mounted() {
    this.height = this.$refs.infoBox.clientHeight;
  }
}
</script>
<style>
  .readmore{
    max-height: 100%;
  }
</style>
