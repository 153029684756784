window.Vue = require('vue')
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import axios from 'axios'

import ReadMore from './components/ReadMore'
import ProductSlider from './components/ProductSlider'
import CookieConsent from './components/CookieConsent'
import AppFixedHeader from './components/AppFixedHeader'
import AppNavBar from './components/AppNavBar'

Vue.component('ReadMore', ReadMore)
Vue.component('CookieConsent', CookieConsent)
Vue.component('ProductSlider', ProductSlider)
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)

const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.prototype.$axios = axios
Vue.config.productionTip = false
