<template>
  <div v-swiper:Cover="cover">
    <!-- <div class="hidden lg:block cover-next absolute right-0 top-2/4 z-10 outline-none w-auto">
      <svg class="text-blue w-12 h-12 transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
    <div class="hidden lg:block cover-prev absolute left-0 top-2/4 z-10 outline-none w-auto">
      <svg class="text-blue w-12 h-12 outline-none transition-colors" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
      </svg>
    </div> -->
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="cover-pagination swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: 'ProductSlider',
  data () {
    return {
      cover: {
        mousewheelControl: false,
        mousewheel:false,
        navigation: {
         nextEl: '.cover-next',
         prevEl: '.cover-prev',
        },
        preventClicks: false,
        pagination: {
          clickable: true,
          el: '.cover-pagination'
        },
        spaceBetween: 0,
        slidesPerView: 1,
        autoplay: {
          delay: 5000
        }
      }
    }
  },
}
</script>
